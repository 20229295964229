import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 5×2\\@85%1RM`}</p>
    <p>{`Barbell Rows 5×2\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`60-Double Unders`}</p>
    <p>{`30-Ring Dips`}</p>
    <p>{`30-Shoulder Press (75/55)(RX+ 95/65)`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`20-Ring Dips`}</p>
    <p>{`20-Shoulder Press`}</p>
    <p>{`20-Double Unders`}</p>
    <p>{`10-Ring Dips`}</p>
    <p>{`10-Shoulder Press`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      